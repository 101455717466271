import React, { useEffect } from "react";
import "../styles/main.css";

// styles
const pageStyles = {
  color: "#EEE",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: "#000",
  margin: "0px",
  padding: "0px",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  display: "flex",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 0,
  maxWidth: 320,
};

// markup
const IndexPage = () => {
  useEffect(() => {
    fetch(`/info`)
      .then((response) => console.log("fetched info"))
      .catch((e) => console.log(e));
  }, []);

  return (
    <main style={pageStyles}>
      <title>jones.co.nz</title>
      <h1 style={headingStyles}>jones.co.nz</h1>
    </main>
  );
};

export default IndexPage;
